<script setup lang="ts">
import { useCmsBlock } from "#imports";
import type {CmsBlockMetrics} from "~/types/cmsBlockTypes";
import type {BlockType} from "@shopware-pwa/composables-next";

const props = defineProps<{
  content: CmsBlockMetrics;
}>();

const { getSlotContent } = useCmsBlock(props.content);

const testimonialsContent = getSlotContent("testimonialsSlot");
</script>
<template>
  <CmsGenericElement :content="testimonialsContent" />
</template>
